.image-preview-container {
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 8px;

  .preview-title {
    margin-bottom: 16px !important;
  }

  .device-selector {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-bottom: 16px;
  }

  .preview-frame {
    width: 100%;
    margin: 0 auto;
    background: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
  }

  .aspect-ratio-box {
    position: relative;
    width: 100%;
    background: #f0f0f0;

    .image-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .preview-image {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
        display: block !important;

        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover !important;
        }
      }
    }

    .watermark {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      color: rgba(0, 0, 0, 0.15);
      font-weight: bold;
      pointer-events: none;
      text-transform: uppercase;
      white-space: nowrap;
      z-index: 1;
    }
  }

  .device-info {
    margin-top: 12px;
    text-align: right;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }
} 